body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --amplify-primary-color: #11a29b;
  --amplify-primary-tint: #3f6060;
  --amplify-primary-shade: #2dba89;
  --amplify-background-color: #e0faf4;
}



/* Override the default iOS styles for the Authenticator widget */

/* Remove the background color of the Authenticator widget */
.amplify-authenticator {
  background-color: transparent !important;
}

/* Remove the border radius of the Authenticator widget */
.amplify-authenticator .amplify-form-section {
  border-radius: 0 !important;
}

/* Customize the font size and color of the Authenticator widget */
.amplify-authenticator .amplify-form-body {
  font-size: 16px;
  color: #333;
}

/* Customize the background color and text color of the buttons in the Authenticator widget */
.amplify-authenticator .amplify-form-button {
  background-color: #007bff;
  color: #fff;
} 

/* Remove the default iOS styles for input fields in the Authenticator widget */
.amplify-authenticator .amplify-form-field {
  -webkit-appearance: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  font-size: 16px;
  color: #333;
  margin-bottom: 15px;
}

/* Customize the focus style of the input fields in the Authenticator widget */
.amplify-authenticator .amplify-form-field:focus {
  outline: none;
  border-color: #007bff !important;
}

/* asf */